import { useContext, useEffect, useState } from "react";
import packagesContext from "../../context/packagesContext";
import NavDisplayCard from "../../components/NavDisplayCard/NavDisplayCard";
import Search from "../../components/SearchBar/Search";
import styled from "styled-components";
import PackageCard from "../../components/Card/PackageCard/PackageCard";
import { convertToSlug } from "../../functions";
import React from "react";
import ResultsNotFoundError from "../../components/Alert/ResultsNotFoundError";
import { Helmet } from "react-helmet";

const TourPackages = () => {
  const context = useContext(packagesContext);

  const [filteredDetails, setFilteredDetails] = useState(context?.tourPackages);
  const [quickSearchBtn, setQuickSearchBtn] = useState("all");

  useEffect(() => {
    context.setTopLoadingBarProgress(0);
    setFilteredDetails(
      context?.tourPackages?.sort((a, b) => {
        const orderA = a.packageTitle
        const orderB = b.packageTitle
        if (orderA < orderB) {
          return -1;
        }
        if (orderA > orderB) {
          return 1;
        }
        // names must be equal
        return 0;
      })
    );
    console.log(context?.tourPackages)
    context.setTopLoadingBarProgress(100);
  }, []);

  const searchPackage = (e) => {
    const searchValue = e.target.value;
    if (searchValue === "") {
      setFilteredDetails(null);
    }
    const filtered = context?.tourPackages.filter((tourPackage) =>
      tourPackage?.packageTitle
        .toLowerCase()
        .includes(searchValue.toLowerCase())
    );
    setFilteredDetails(filtered);
  };

  const applyQuickFilter = (quickFilterValue) => {
    if (quickFilterValue === "all") {
      setFilteredDetails(context?.tourPackages);
      setQuickSearchBtn("all");
      return;
    }

    const filtered = context?.tourPackages?.filter((tourPackage) =>
      tourPackage?.destination?.toLowerCase().includes(quickFilterValue)
    );
    setFilteredDetails(filtered);
    setQuickSearchBtn(quickFilterValue);
  };

  return (
    <React.Fragment>
      <Helmet>
        <html lang="en" />

        <meta
          name="description"
          content="Discover the finest tour packages from Bengaluru with HampiTrip. Explore exciting destinations and create memorable experiences. Plan your journey with us today."
        />
        <title>
          Discover the Best Tour Packages for Hampi, Badami| HampiTrip | Ganga
          Tours and Travels
        </title>
      </Helmet>
      <NavDisplayCard navStatus={"Tour Packages"} />
      <div className="container mb-5">
        <Search searchPackage={searchPackage} />
        <div className="continer d-flex flex-wrap align-items-center justify-content-center gap-2 mt-4 cursor-pointer">
          <span>Quick Search: </span>

          {context.quickSearchBadges?.map((document) =>
            document.cityNames.map((city) => {
              return (
                <Button
                  key={city}
                  className="badge rounded-pill grow"
                  style={{ fontSize: "14px", cursor: "pointer" }}
                  onSelectQuickFilter={
                    quickSearchBtn === city.toLowerCase() ? true : false
                  }
                  onClick={() => applyQuickFilter(city.toLowerCase())}
                >
                  {city}
                </Button>
              );
            })
          )}
        </div>
        {filteredDetails.length > 0 ? (
          filteredDetails?.map((tourPackage) => (
            <PackageCard
              key={tourPackage?.packageTitle}
              packageTitle={tourPackage.packageTitle}
              imageToUrl={tourPackage.thumbnailPicture}
              days={tourPackage.days}
              tourCode={tourPackage.tourCode}
              actualPrice={tourPackage.actualPrice}
              marketingPrice={tourPackage.marketingPrice}
              enquireNowUrl={tourPackage.enquireNowUrl}
              slug={convertToSlug(tourPackage?.packageTitle)}
            />
          ))
        ) : (
          <ResultsNotFoundError />
        )}
      </div>
    </React.Fragment>
  );
};

export default TourPackages;

const Button = styled.span`
  background: ${(props) =>
    props.onSelectQuickFilter ? "green" : "var(--blue)"} !important;
`;
